export enum SetEffectTypes {
  JUSTICIAR = 'JUSTICIAR'
}
export class SetEffect {
  static effectName(): string {
    return '';
  }
  static itemsInSet(): string[] {
    return [ ]
  }
}