'use strict';

export enum EntityName {
  TILE_MARKER = 'Tile Marker',
  INFERNO_SHIELD = 'Inferno Shield',
  TZ_KAL_ZUK = 'TzKal-Zuk',
  JAL_XIL = 'Jal-Xil',
  JAL_ZEK = 'Jal-Zek',
  YT_HUR_KOT = 'Yt-HurKot',
  JAL_TOK_JAD = 'JalTok-Jad',
  JAL_NIB = 'Jal-Nib',
  JAL_MEJ_RAJ = 'Jal-MejRah',
  JAL_MEJ_JAK = 'Jal-MejJak',
  JAL_IM_KOT = 'Jal-ImKot',
  JAL_AK_REK_XIL = 'Jal-AkRek-Xil',
  JAL_AK_REK_MEJ = 'Jal-AkRek-Mej',
  JAL_AK_REK_KET = 'Jal-AkRek-Ket',
  JAL_AK = 'Jal-Ak'
}
