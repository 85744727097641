
export enum ItemName {
  BLOOD_BARRAGE = 'Blood Barrage',
  ICE_BARRAGE = 'Ice Barrage',
  AHRIMS_ROBETOP = 'Ahrim\'s robetop',
  AHRIMS_ROBESKIRT = 'Ahrim\'s robeskirt',
  ANCESTRAL_ROBEBOTTOM = 'Ancestral Robe bottom',
  ANCESTRAL_ROBETOP = 'Ancestral Robe top',
  ANCIENT_STAFF = 'Ancient staff',
  ARMADYL_CHAINSKIRT = 'Armadyl Chainskirt',
  ARMADYL_CHESTPLATE = 'Armadyl Chestplate',
  AVAS_ACCUMULATOR = "Ava's accumulator",
  AVAS_ASSEMBLER = 'Avas Assembler',
  BARROWS_GLOVES = 'Barrows Gloves',
  BASTION_POTION = 'Bastion Potion',
  BLACK_D_HIDE_BODY = "Black d'hide body",
  BLACK_D_HIDE_CHAPS = "Black d'hide chaps",
  BLACK_D_HIDE_VAMBRACES = "Black d'hide vambraces",
  BOWFA = 'Bow of faerdhinen',
  CRYSTAL_BODY = 'Crystal Body',
  CRYSTAL_HELM = 'Crystal Helm',
  CRYSTAL_LEGS = 'Crystal Legs',
  CRYSTAL_SHIELD = 'Crystal Shield',
  DAGON_HAI_ROBE_TOP = "Dagon'hai robe top",
  DEVOUT_BOOTS = 'Devout Boots',
  DIAMOND_BOLTS_E = 'Diamond bolts (e)',
  DRAGON_ARROWS = 'Dragon arrow',
  GUTHIX_ROBE_TOP = 'Guthix robe top',
  HOLY_BLESSING = 'Holy Blessing',
  JUSTICIAR_CHESTGUARD = 'Justiciar Chestguard',
  JUSTICIAR_FACEGUARD = 'Justiciar Faceguard',
  JUSTICIAR_LEGGUARDS = 'Justiciar Legguards',
  KARAMBWAN = 'Karambwan',
  KODAI_WAND = 'Kodai Wand',
  MAGES_BOOK = "Mage's Book",
  NECKLACE_OF_ANGUISH = 'Necklace of Anguish',
  OCCULT_NECKLACE = 'Occult necklace',
  PEGASIAN_BOOTS = 'Pegasian Boots',
  RANGER_BOOTS = 'Ranger boots',
  RING_OF_ENDURANCE = 'Ring of Endurance',
  RING_OF_SUFFERING_I = 'Ring of Suffering (i)',
  ROBIN_HOOD_HAT = 'Robin hood hat',
  RUBY_BOLTS_E = 'Ruby bolts (e)',
  RUNE_CROSSBOW = 'Rune Crossbow',
  RUNE_KITESHIELD = 'Rune kiteshield',
  SARADOMIN_BREW = 'Saradomin Brew',
  SARADOMIN_COIF = 'Saradomin coif',
  SARADOMIN_D_HIDE_BODY = "Saradomin d'hide body",
  SARADOMIN_D_HIDE_BOOTS = "Saradomin d'hide boots",
  SARADOMIN_D_HIDE_CHAPS = "Saradomin chaps",
  SCYTHE_OF_VITUR = 'Scythe of Vitur',
  SHARK = 'Shark',
  SLAYER_HELMET_I = 'Slayer helm (i)',
  STAMINA_POTION = 'Stamina Potion',
  SUPER_RESTORE = 'Super Restore',
  TOXIC_BLOWPIPE = 'Toxic Blowpipe',
  TWISTED_BOW = 'Twisted Bow',
}